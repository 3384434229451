import React from 'react'
import { Link, graphql, StaticQuery } from 'gatsby'
import { Lunr } from 'react-lunr'
import {
  ItemTransition,
  ListTransition,
  PageTransition,
} from '../components/transition'
import Img from 'gatsby-image'
import { Helmet } from 'react-helmet'
import styled from '@emotion/styled'
import theme from '../theme/theme'
import { css } from '@emotion/core'
import color from 'color'
import { PoseGroup } from 'react-pose'

const styledList = css({
  padding: 0,
  margin: 0,
  marginTop: '129px',
  paddingTop: '31px',
  listStyle: 'none',

  [`@media (min-width: ${theme.mqLayout})`]: {
    background: 'none',
  },

  ...theme.backgroundPlaid,
  backgroundAttachment: 'scroll',
  backgroundRepeat: 'repeat-x',
  backgroundPosition: 'center top',
  backgroundColor: 'transparent',
})

const styledItem = css({
  marginBottom: '1px',
  position: 'relative',
})

const ItemContent = styled.div({
  position: 'absolute',
  bottom: 0,
  padding: '20px',
  background:
    'linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0.65) 100%)',
  width: '100%',
})

const styledPage = css({
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
})

const Title = styled.h3({
  color: theme.light,
  fontSize: '1.6em',
})

const styledImage = css({})
/**
 *
 * @param data daten
 * @param data.allMarkdownRemark
 * @param data.totalCount
 * @param data.edges
 * @returns {*}
 * @constructor
 */
class IndexPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      query: 'speise',
    }
  }
  render() {
    return (
      <PageTransition css={styledPage}>
        <Helmet>
          <title>Speiseliste</title>
        </Helmet>

        <StaticQuery
          query={graphql`
            {
              localSearchPages {
                index
                store
              }
            }
          `}
          render={data => (
            <Lunr
              index={data.localSearchPages.index}
              store={data.localSearchPages.store}
              query={this.state.query}
            >
              {({ results, setQuery }) => (
                <>
                  <ul css={styledList}>
                    {results.map((result, i) => (
                      <li css={styledItem} key={result.id} i={i}>
                        <div>
                          <Img
                            css={styledImage}
                            fluid={
                              JSON.parse(result.cover_image).childImageSharp
                                .fluid
                            }
                          />
                          <ItemContent>
                            <Title>{result.title}</Title>
                            <Link to={result.path}>{result.title}</Link>
                          </ItemContent>
                        </div>
                      </li>
                    ))}
                  </ul>
                </>
              )}
            </Lunr>
          )}
        />
      </PageTransition>
    )
  }
}

export default IndexPage

/*
* <button
                    onClick={() => {
                      this.setState({ query: 'Bratkartoffeln' })
                    }}
                  >
                  Bratkartoffeln
                  </button>
* */
